import { ModalForm, ImageInput, Input, Chip } from 'eureka-design'
import ContenedorScroll from '../../components/ContenedorScroll/ContenedorScroll'

const FormUsuario = ({
    usuario = null,
    mostrar = true,
    handlerMostrar = () => {},
}) => {
    return (
        <ModalForm 
            show={mostrar}
            onClose={() => { handlerMostrar(false) }}
            titulo="Agregar un nuevo usuario"
        >
            <ContenedorScroll>
                <ImageInput 
                    width={250}
                    height={250}
                />
                <Input 
                    iconLeft="fa-user"
                    placeholder="Nombre"
                />
                <Input 
                    iconLeft="fa-envelope"
                    placeholder="Correo eléctronico"
                />
                <Input 
                    iconLeft="fa-phone"
                    placeholder="Teléfono"
                />
                <h4>Dirección</h4>
                <div style={{ display: 'grid', gap: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Input 
                            placeholder="Calle"
                        />
                        <div>
                            <Input
                                placeholder="N° exterior"
                            />
                        </div>
                        <div>
                            <Input
                                placeholder="N° interior"
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ width: '57%' }}>
                            <Input 
                                placeholder="Código postal"
                            />
                        </div>
                        <Input
                            placeholder="Domicilio"
                        />
                        <Input
                            placeholder="Colonia"
                        />
                    </div>
                </div>
                <h4>Roles de usuario</h4>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '16px' }}>
                    <Chip icono="fa-user" texto="Administrador" />
                    <Chip icono="fa-user" texto="Cliente" />
                    <Chip icono="fa-user" texto="Vendedor" />
                </div>
            </ContenedorScroll>
        </ModalForm>
    )
}

export default FormUsuario