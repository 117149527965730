
const encontrarCol = (dato = {}, prop = '') => {
    let props = prop.split('.')
    let valor = dato    
    props.forEach(prop => {
        valor = valor[prop] ?? ''
    })
    return valor
}

export const adaptarDatos = (
    datos = [], 
    propTexto = '', 
    propValor = '', 
    callbackTexto = dato => dato, 
    callbackValor = dato => dato,
) => {
    datos = datos.map(dato => {
        return {
            text: propTexto?.length > 0 ? encontrarCol(dato, propTexto) : callbackTexto(dato),
            id: propValor?.length > 0 ? encontrarCol(dato, propValor) : callbackValor(dato),
            item: dato
        }
    })
    return datos
}