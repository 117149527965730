import { ModalForm, Select, Input } from 'eureka-design'
import Membrete from '../../components/Membrete/Membrete'
import logo from '../../imgs/icono.svg'
import ContenedorScroll from '../../components/ContenedorScroll/ContenedorScroll'
import TablaConceptos from '../../components/TablaConceptos/TablaConceptos'
import { numberFormat } from '../../js/util'
import { adaptarDatos } from '../../js/selector'
import { useEffect, useMemo, useState } from 'react'
import Firma from '../../components/Firma/Firma'

const estiloDiv = {
    display: 'grid', 
    gridTemplate: '1fr / 1fr auto',
    gap: '5px',
}

const FormRemision = ({
    remision = null,
    mostrar = true,
    handlerMostrar = () => {},
}) => {
    const productos = [
        { id: 1, nombre: '12345', inventario: 2, precio: 200 },
        { id: 2, nombre: '12345', inventario: 1, precio: 100 },
        { id: 3, nombre: '12345', inventario: 1, precio: 300 },
        { id: 4, nombre: '12345', inventario: 1, precio: 100 },
    ]
    const conceptosRemision = useMemo(() => [], [])

    const [conceptos, fijaConceptos] = useState(conceptosRemision)
    const [total, fijaTotal] = useState(0)

    useEffect(() => {
        let total = 0
        conceptos.forEach((concepto) => {
            total += Number(concepto.cantidad) * Number(concepto.precio)
        })

        fijaTotal(total)
    }, [fijaTotal, conceptos])

    const handlerConcepto = (tipo, data, indice = 0) => {
        if(tipo === 'agregar') {
            let producto = data
            fijaConceptos([
                ...conceptos,
                { nombre: producto?.nombre, cantidad: 1, precio: producto?.precio }
            ])
        }
        else if(tipo === 'editar') {
            let concepto = data
            let conceptosEditados = [...conceptos]

            conceptosEditados[indice] = concepto
            fijaConceptos(conceptosEditados)
        }
        // else tipo no reconocido
    }

    return (
        <ModalForm
            show={mostrar}
            titulo="Crear una nueva remisión"
            botones={[
                {
                    texto: "Crear remisión",
                    onClick: () => {},
                },
            ]}
            onClose={() => {
                handlerMostrar(false)
            }}
        >
            <ContenedorScroll>
                <Membrete 
                    logo={logo}
                />
                <div style={estiloDiv}>
                    <Select 
                        nombre="cliente"
                        options={[]}
                        value={'Seleccione un cliente...'}
                        changeFunction={() => {}}
                        busqueda={{ placeholder: 'Nombre del cliente' }}
                    />
                    <Input 
                        placeholder="Remisión"
                    />
                </div>
                <div style={estiloDiv}>
                    <Select 
                        nombre="ciudad"
                        options={[]}
                        value={'Seleccione una ciudad...'}
                        changeFunction={() => {}}
                        busqueda={{ placeholder: 'Ciudad' }}
                    />
                    <Input 
                        placeholder="Fecha"
                    />
                </div>

                <TablaConceptos 
                    encabezados={[
                        { texto: 'ID Producto', tam: 'xl' },
                        { texto: 'Cant.', tam: 'sm' },
                        { texto: 'Precio',   tam: 'sm' },
                        { texto: 'Total',    tam: 'sm' },
                    ]}
                    estructura={[
                        { col: 'nombre',  tipo: 'texto',  tam: 'xl', },
                        { col: 'cantidad', tipo: 'numero', tam: 'sm', incrementable: true },
                        { col: 'precio',  tipo: 'precio', tam: 'sm', },
                        { 
                            col: 'total',
                            tipo: 'precio', 
                            tam: 'sm',
                            text: (item) => {
                                return numberFormat(item.cantidad * item.precio)
                            },
                        },
                    ]}
                    conceptos={conceptos}
                    productos={adaptarDatos(productos, 'nombre', 'id')}
                    onChange={handlerConcepto}
                    total={total}
                />

                <Firma 
                    titulo="Firma de conformidad"
                    subTitulo="Ingresa tu firma en el siguiente recuadro"
                    onSave={(img) => {
                        console.log(img)
                    }}
                />
            </ContenedorScroll>
        </ModalForm>
    )
}

export default FormRemision