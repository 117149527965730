import { useCallback, useEffect, useState } from 'react'
import { ButtonIcon } from 'eureka-design'
import './InputCount.css'

const InputCount = ({ 
    label = 'Cuenta', 
    name = '', 
    value = 0, 
    onChange = () => {}, 
    min = 0, 
    max = 99 
}) => {
    const adaptValue = useCallback((value = 0) => {
        let valueStr = String(value)
        let digits = max.toString().length
        let zeros = '' 

        digits = digits - valueStr.length
        for(let i = 0; i < digits; i++) {
            zeros = `${zeros}0`
        }
        return `${zeros}${valueStr}`
    }, [max])

    const handlerCount = (type = 'input') => {
        let val = count
        if(type === 'input') {
            setViewInput(true)
        }
        else if(type === 'minus') {
            val = count > min ? count - 1 : min
            onChange(name, val)
        }
        else if(type === 'plus') {
            val = count < max ? count + 1 : max
            onChange(name, val)
        }
        setValueInput(val)
        setCount(val)
        setCountStr(adaptValue(val))
    }

    const handlerEnter = (event) => {
        if (event.key === 'Enter') {
            let val = parseInt(valueInput)
            val = (val >= min && val <= max) ? val : min

            setViewInput(false)
            onChange(name, val)
            setValueInput(val)
            setCount(val)
            setCountStr(adaptValue(val))
        }
        // else is other key
    }

    useEffect(() => {
        setCount(parseInt(value ?? 0))
        setCountStr(adaptValue(value ?? 0))
    }, [value, adaptValue])

    const [count, setCount] = useState(parseInt(value ?? 0))
    const [countStr, setCountStr] = useState(adaptValue(value ?? 0))
    const [valueInput, setValueInput] = useState(parseInt(value ?? 0))
    const [viewInput, setViewInput] = useState(false)

    return (
        <div className="container-input-count">
            <p className="container-input-count__label">{label}</p>
            <div className="container-input-count__input">
                <ButtonIcon 
                    icono="fa-minus" 
                    background="var(--color-card)" 
                    onClick={() => { handlerCount('minus') }} 
                />
                {
                    viewInput ? 
                        <input 
                            id="inputRef"
                            value={valueInput}
                            type="text"
                            className="container-input-count__input__input"
                            onChange={(e) => { setValueInput(e.target.value) }}
                            onKeyDown={handlerEnter}
                        /> :
                        <span 
                            className="container-input-count__input__value" 
                            onClick={() => { handlerCount('input') }}
                        >
                            {countStr}
                        </span>
                    }
                <ButtonIcon 
                    icono="fa-plus" 
                    background="var(--color-card)" 
                    onClick={() => { handlerCount('plus') }} 
                />
            </div>
        </div>
    )
}

export default InputCount