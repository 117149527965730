import './BotonIcono.css'

const BotonIcono = ({ icono = '', onClick = () => {} }) => {
    return (
        <div className="boton-icono" onClick={onClick}>
            <i className={`fa-solid ${icono} boton-icono__icono`}></i>
        </div>
    )
}

export default BotonIcono