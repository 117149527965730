import { useMemo } from 'react'
import { Card, ProgressBar, LoaderCircle } from 'eureka-design'
import './ModalCargando.css'

const ModalCargando = ({ mostrar = false, modo = 'progreso', progreso = 50, titulo = 'Cargando', texto = 'Espera un momento...' }) => {
    const modal = useMemo(() => {
        return (
            <div className="modal" style={mostrar ? { opacity: 1, visibility: "visible" } : {}}>
                <div className="modal-content modal-content--cargando">
                    <Card>
                        <div className="contenedor-modal-cargando">
                            {
                                (modo === 'progreso') ? 
                                    <ProgressBar progress={progreso} size="medium" /> :
                                    <LoaderCircle size="medium" />
                            }
                            <br />
                            <h4 className="contenedor-modal-cargando__titulo">{titulo}</h4>
                            <p className="contenedor-modal-cargando__texto">{texto}</p>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }, [mostrar, modo, progreso, titulo, texto])

    return modal
}

export default ModalCargando