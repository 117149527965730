import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import FormRemision from './form-remision'
import { useState } from 'react'
import { obtenerData } from '../../js/util'
import { useObtenerRemisionesQuery } from '../../services/remision'

const ListaRemisiones = ({ ChangeScene }) => {
    const { data, isLoading } = useObtenerRemisionesQuery()

    const [mostrarModalRegistro, cambiarEstadoModalRegistro] = useState(false)
    const [remision, fijaRemision] = useState(false)

    const handlerRegistrar = () => {
        fijaRemision(null)
        cambiarEstadoModalRegistro(true)
    }

    const handlerDetalles = (item) => {
        let remision = item.item
        fijaRemision(remision)
        cambiarEstadoModalRegistro(true)
    }

    if(isLoading) return
    else {
        return (
            <>
                <FormRemision 
                    remision={remision}
                    mostrar={mostrarModalRegistro}
                    handlerMostrar={cambiarEstadoModalRegistro}
                />

                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay remisiones aún"
                    busqueda={{ placeholder: "Buscar...", keys: ["id"] }}
                    keys={[
                        {
                            icono: 'fa-copy',
                            text: "Todos",
                            detalle: 0,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Pagadas",
                            detalle: 0,
                            filtro: item => item.item.cxp_cxc === "liquidado"
                        },
                        {
                            icono: 'fa-file-invoice-dollar',
                            text: "Pendientes de pago",
                            detalle: 0,
                            filtro: item => item.item.cxp_cxc === "cobrar"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Sin pagar",
                            detalle: 0,
                            filtro: item => item.item.cxp_cxc === "pagar"
                        },
                    ]}
                    headers={[
                        { key: 'id',               text: 'N° de Pedido',          type: 'text' },
                        { key: 'cliente',          text: 'Cliente',               type: 'text' },
                        { key: 'estatus_whatsapp', text: 'Enviado por Whatsapp',  type: 'text' },
                        { key: 'productos',        text: 'Productos Solicitados', type: 'text' },
                        { key: 'total',            text: 'Kg Total',              type: 'number' },
                        { key: 'costo',            text: 'Costo',                 type: 'precio' },
                        { key: 'fecha',            text: 'Fecha de Creación',     type: 'date' },
                        { key: 'cxp_cxc',          text: 'Estado',                type: 'text' },
                    ]}
                    data={buildData(data, [
                        { key: 'id', type: 'text' },
                        { key: 'cliente', type: 'text' },
                        { 
                            key: 'estatus_whatsapp', 
                            type: 'text', 
                            text: (item) => {
                                let guia = obtenerData(item, 'eureka_en_guias')
                                let texto = ''
                                let tipo = ''

                                if(guia.estatus === 'enviado') {
                                    texto = 'Enviada'
                                    tipo = 'Aceptado'
                                }
                                else if(guia.estatus === 'esperando') {
                                    texto = 'Sin enviar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                        { 
                            key: 'productos', 
                            type: 'text',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                return `${conceptos.length} productos`
                            }
                        },
                        { 
                            key: 'kilo', 
                            type: 'number',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                let total = 0

                                conceptos.forEach((c) => {
                                    total += parseFloat(c.cantidad)
                                })
                                return total
                            }
                        },
                        { key: 'total', type: 'precio' },
                        { key: 'fecha', type: 'date' },
                        { 
                            key: 'cxp_cxc', 
                            type: 'text',
                            text: (item) => {
                                let texto = '', tipo = ''
                                if(item.cxp_cxc === 'liquidado') {
                                    texto = 'Pedido pagado'
                                    tipo = 'Aceptado'
                                }
                                else if(item.cxp_cxc === 'cobrar') {
                                    texto = 'Pendiente de pago'
                                    tipo = 'Pendiente'
                                }
                                else {
                                    texto = 'Sin pagar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    onClickRow={handlerDetalles}
                />
            </>
        )
    }
}

export default ListaRemisiones