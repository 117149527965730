import { Tabla, buildData, ChipEstatus } from 'eureka-design'

const ListaPedidosProveedores = ({ UpProps }) => {
    const data = [
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', whatsapp: 'enviada', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', whatsapp: 'enviada', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', whatsapp: 'no enviada', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'pendiente' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', whatsapp: 'enviada', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', whatsapp: 'no enviada', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'cobrar' },
    ]

    const handlerRegistrar = () => {
    }

    const handlerDetalles = (item) => {
    }

    return (
        <>
            <Tabla
                checkbox={false}
                rowSize="medium"
                noData="No hay pedidos aún"
                busqueda={{ placeholder: "Buscar...", keys: ["id"] }}
                keys={[
                    {
                        icono: 'fa-copy',
                        text: "Todos",
                        detalle: 0,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-check',
                        text: "Pagadas",
                        detalle: 0,
                        filtro: item => item.item.estatus === "liquidado"
                    },
                    {
                        icono: 'fa-file-invoice-dollar',
                        text: "Pendientes de pago",
                        detalle: 0,
                        filtro: item => item.item.estatus === "cobrar"
                    },
                    {
                        icono: 'fa-triangle-exclamation',
                        text: "Sin pagar",
                        detalle: 0,
                        filtro: item => item.item.estatus === "pagar"
                    },
                ]}
                headers={[
                    { key: 'id',        text: 'N° de Pedido',          type: 'text' },
                    { key: 'cliente',   text: 'Cliente',               type: 'text' },
                    { key: 'whatsapp',  text: 'Enviado por Whatsapp',  type: 'text' },
                    { key: 'productos', text: 'Productos solicitados', type: 'text' },
                    { key: 'kilos',     text: 'Kg total',              type: 'number' },
                    { key: 'costo',     text: 'Costo',                 type: 'precio' },
                    { key: 'fecha',     text: 'Fecha de creación',     type: 'date' },
                    { key: 'estatus',   text: 'Estado',                type: 'text' },
                ]}
                data={buildData(data, [
                    { key: 'id',        type: 'text' },
                    { key: 'cliente',   type: 'text' },
                    { 
                        key: 'whatsapp',  
                        type: 'text',
                        text: (item) => {
                            let texto, tipo
                            if(item.whatsapp === 'enviada') {
                                texto = 'Enviada'
                                tipo = 'Aceptado'
                            }
                            else {
                                texto = 'Sin enviar'
                                tipo = 'Error'
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                    { key: 'productos', type: 'text' },
                    { key: 'kilos',     type: 'number' },
                    { 
                        key: 'costo',
                        type: 'precio',
                        text: (item) => `${item.productos} productos`
                    },
                    { key: 'fecha',     type: 'date' },
                    { 
                        key: 'estatus',
                        type: 'text',
                        text: (item) => {
                            let texto = '', tipo = ''
                            if(item.estatus === 'liquidado') {
                                texto = 'Pedido pagado'
                                tipo = 'Aceptado'
                            }
                            else if(item.estatus === 'cobrar') {
                                texto = 'Pendiente de pago'
                                tipo = 'Pendiente'
                            }
                            else {
                                texto = 'Sin pagar'
                                tipo = 'Error'
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                ])}
                botonesHead={[
                    { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                ]}
                onClickRow={handlerDetalles}
            />
        </>
    )
}

export default ListaPedidosProveedores