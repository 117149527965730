import './Mapa.css'
import mapa from '../../imgs/mapa-clientes-demo.png'
import Busquedad from './Busquedad/Busquedad'

const Mapa = ({
    src = mapa,
    titulo = "Andrea López Martínez",
    descripcion = "Calle Caracol N°24 La Corregidora, La Palma, Guadalajara, 12345",
    detalles = [
        { texto: '112 clientes en total' },
        { texto: '9 clientes en esta zona' },
    ],
}) => {
    return (
        <div className="contenedor-mapa">
            <Busquedad 
                placeholder="Buscar cliente..."
                icono="fa-magnifying-glass"
                nombre="nombre"
                valor={null}
                opciones={[]}
                onChange={() => {}}
                inputs={[
                    { placeholder: 'Municipio', nombre: "municipio", valor: null, opciones: [] },
                    { placeholder: 'Colonia', nombre: "colonia", valor: null, opciones: [] }
                ]}
            />

            <div className="contenedor-mapa__detalles">
                {
                    detalles.map((d, i) => {
                        return <p className="contenedor-mapa__detalles__subtitulo" key={i}>{d.texto}</p>
                    })
                }
            </div>
            <h3 className="contenedor-mapa__detalles__titulo">{titulo}</h3>
            <p className="contenedor-mapa__detalles__subtitulo">{descripcion}</p>

            <div className="contenedor-mapa__vista">
                <img 
                    className="contenedor-mapa__vista" 
                    src={src} 
                    alt="mapa de clientes" 
                />
            </div>
        </div>
    )
}

export default Mapa