import { useState } from 'react'
import { ModalForm, DropFile } from 'eureka-design'
import ModalCargando from '../../components/ModalCargando/ModalCargando'
import TablaProductos from '../../components/TablaProductos/TablaProductos'
import { useObtenerProductosQuery } from '../../services/producto'
import fotoError from '../../imgs/producto-demo.png'

const ListaProductos = ({ ShowAlert, Push }) => {
    const { data:productos, isLoading } = useObtenerProductosQuery()

    const [mostrandoModalActualizar, verModalActualizar] = useState(false)
    const [mostrandoModalCargando, verModalCargando] = useState(false)

    const handlerAgregar = () => {
        Push('form_producto', {
            producto: null
        })
    }

    const handlerEditar = (item) => {
        Push('form_producto', {
            producto: item,
            titulo: `${item?.nombre}`
        })
    }

    const handlerAbrirModalActualizar = () => {
        verModalActualizar(true)
    }

    const handlerActualizarInventario = () => {
        verModalActualizar(false)
        verModalCargando(true)
    }

    const handlerBuscar = () => {

    }

    if(isLoading) return
    else {
        return (
            <>
                <ModalForm
                    show={mostrandoModalActualizar}
                    titulo="Actualizar inventario"
                    onClose={() => {
                        verModalActualizar(false)
                    }}
                    botones={[
                        { texto: 'Actualizar inventario', onClick: handlerActualizarInventario }
                    ]}
                >
                    <p><b>Sube el archivo con tus productos para poder actualizar tu inventario de manera masiva</b></p>
                    <br />
                    <DropFile />
                </ModalForm>

                <ModalCargando 
                    mostrar={mostrandoModalCargando} 
                    modo="normal"
                    titulo="Actualizando inventario"
                    texto="Espera un momento..."
                />

                <TablaProductos 
                    noDataMsj="Aún no hay productos para mostrar"
                    tipo="Kg."
                    fotoError={fotoError}
                    data={productos}
                    categorias={[]}
                    buquedad={{ 
                        nombre: 'id',
                        icono: 'fa-magnifying-glass',
                        opciones: [],
                        value: null,
                        placeholder: 'Buscar producto...',
                        onChage: handlerBuscar
                    }}
                    botonesHeader={[
                        { texto: 'Actualizar inventario', icono: 'fa-rotate', onClick: handlerAbrirModalActualizar },
                        { tooltip: 'Agregar', icono: 'fa-plus', onClick: handlerAgregar },
                    ]}
                    onClickCard={handlerEditar}
                />
            </>
        )
    }
}

export default ListaProductos