import './CardDetalle.css'

const CardDetalle = ({
    titulo = 'Sin titulo',
    texto = 'Ningúno',
    modoInformacionGrande = false,
    mostrarIcono = true,
    icono = 'fa-circle-check',
    color = '#0F0F0F',
}) => {
    return (
        <div className="card-detalle">
            <p className={`${ modoInformacionGrande ? 'card-detalle__extra__texto' : 'card-detalle__titulo' }`}>{titulo}</p>
            <div className="card-detalle__extra">
                <p className={`${ modoInformacionGrande ? 'card-detalle__titulo' : 'card-detalle__extra__texto' }`}>{texto}</p>
                { 
                    (mostrarIcono) ? 
                        <div className="card-detalle__extra__icono">
                            <div className="card-detalle__extra__icono__fondo" style={{ background: color }}></div>
                            <i className={`fa-solid ${icono} card-detalle__extra__icono__icono`} style={{ color: color }}></i> 
                        </div>
                        : undefined 
                }
            </div>
        </div>
    )
}

export default CardDetalle