import './Busquedad.css'
import { Select } from 'eureka-design'

const Busquedad = ({
    placeholder = "Buscar cliente...",
    icono = "fa-magnifying-glass",
    nombre = "nombre",
    valor = null,
    opciones = [],
    onChange = () => {},
    inputs = [
        { placeholder: 'Municipio', nombre: "municipio", valor: null, opciones: [] },
        { placeholder: 'Colonia', nombre: "colonia", valor: null, opciones: [] }
    ]
}) => {
    return (
        <div className="busquedad-contenedor">
            <div className="busquedad-contenedor__select-busquedad">
                <Select 
                    nombre={nombre}
                    iconLeft={icono}
                    options={opciones}
                    value={valor ?? placeholder}
                    changeFunction={onChange}
                    busqueda={{ placeholder: placeholder }}
                />
            </div>
            {
                inputs.map((input, i) => {
                    return (
                        
                            <Select 
                                key={i} 
                                nombre={input.nombre}
                                options={input.opciones}
                                value={input.valor ?? input.placeholder}
                                changeFunction={onChange}
                                busqueda={{ placeholder: input.placeholder }}
                            />
                        
                    )  
                })
            }
        </div>
    )
}

export default Busquedad