import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_ROL = 'EurekaCrRolUsuario'

export const rolAPI = createApi({
    reducerPath: 'rolAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Roles'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        darRol: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_ROL)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    })
})


export const { 
    useDarRolMutation
} = rolAPI