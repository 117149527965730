import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useEffect, useState } from 'react'
import DetalleRemision from '../remisiones/detalle-remision'

const ListaRemisionesUsuario = ({ UpTittle, usuario }) => {
    const data = [
        { id: 12345, productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'pendiente' },
        { id: 12345, productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'liquidado' },
        { id: 12345, productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', estatus: 'cobrar' },
    ]

    const [remision, fijaRemision] = useState(false)
    const [mostrarDetalles, fijaMostrarDetalles] = useState(false)

    useEffect(() => {
        UpTittle(`Remisiones de ${usuario?.nombre ?? 'usuario'}`)
    })

    const handlerRegistrar = () => {
        fijaRemision(null)
    }

    const handlerDetalles = (item) => {
        let remision = item.item
        fijaRemision(remision)
        fijaMostrarDetalles(true)
    }


    return (
        <>
            <DetalleRemision 
                remision={remision}
                mostrar={mostrarDetalles}
                onClose={() => { fijaMostrarDetalles(false) }}
            />

            <Tabla
                checkbox={false}
                rowSize="medium"
                noData="No hay remisiones aún"
                busqueda={{ placeholder: "Buscar pedido...", keys: ["id"] }}
                keys={[
                    {
                        icono: 'fa-copy',
                        text: "Todos",
                        detalle: 200,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-check',
                        text: "Pagadas",
                        detalle: 2,
                        filtro: item => item.item.estatus === "liquidado"
                    },
                    {
                        icono: 'fa-file-invoice-dollar',
                        text: "Pendientes de pago",
                        detalle: 2,
                        filtro: item => item.item.estatus === "cobrar"
                    },
                    {
                        icono: 'fa-triangle-exclamation',
                        text: "Sin pagar",
                        detalle: 2,
                        filtro: item => item.item.estatus === "pagar"
                    },
                ]}
                headers={[
                    { key: 'id',        text: 'N° de Pedido',          type: 'text' },
                    { key: 'productos', text: 'Productos solicitados', type: 'text' },
                    { key: 'kilos',     text: 'Kg total',              type: 'number' },
                    { key: 'costo',     text: 'Costo',                 type: 'precio' },
                    { key: 'fecha',     text: 'Fecha de creación',     type: 'date' },
                    { key: 'estatus',   text: 'Estado',                type: 'text' },
                ]}
                data={buildData(data, [
                    { key: 'id',        type: 'text' },
                    { key: 'productos', type: 'text' },
                    { key: 'kilos',     type: 'number' },
                    { 
                        key: 'costo',
                        type: 'precio',
                        text: (item) => `${item.productos} productos`
                    },
                    { key: 'fecha',     type: 'date' },
                    { 
                        key: 'estatus',
                        type: 'text',
                        text: (item) => {
                            let texto = '', tipo = ''
                            if(item.estatus === 'liquidado') {
                                texto = 'Pedido pagado'
                                tipo = 'Aceptado'
                            }
                            else if(item.estatus === 'cobrar') {
                                texto = 'Pendiente de pago'
                                tipo = 'Pendiente'
                            }
                            else {
                                texto = 'Sin pagar'
                                tipo = 'Error'
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                ])}
                botonesHead={[
                    { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                ]}
                onClickRow={handlerDetalles}
            />
        </>
    )
}

export default ListaRemisionesUsuario