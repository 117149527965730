import { Tabla, buildData, ChipEstatus, Miniatura } from 'eureka-design'
import { ESTATUS_ACTIVO, obtenerLogo } from '../../js/util'
import { useState } from 'react'
import InfoCliente from './info-cliente'
import FormUsuario from '../usuarios/form-usuario'

const estiloDivNombre = { 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    gap: '5px'
}

const ListaClientes = ({ Push }) => {
    const data = [
        { nombre: 'María Fernanda Goméz Aguilar', correo: 'example@gmail.com', telefono: '555-33333', municipio: 'Jalisco', estatus: 'activo' },
        { nombre: 'María Fernanda Goméz Aguilar', correo: 'example@gmail.com', telefono: '555-33333', municipio: 'Jalisco', estatus: 'activo' },
        { nombre: 'María Fernanda Goméz Aguilar', correo: 'example@gmail.com', telefono: '555-33333', municipio: 'Jalisco', estatus: 'inactivo' },
        { nombre: 'María Fernanda Goméz Aguilar', correo: 'example@gmail.com', telefono: '555-33333', municipio: 'Jalisco', estatus: 'activo' },
        { nombre: 'María Fernanda Goméz Aguilar', correo: 'example@gmail.com', telefono: '555-33333', municipio: 'Jalisco', estatus: 'activo' },
    ]

    const [cliente, fijaCliente] = useState(null)
    const [mostrarModal, cambiarEstadoModal] = useState(false)
    const [mostrarModalDetalles, cambiarEstadoModalDetalles] = useState(false)

    const handlerRegistrar = () => {
        cambiarEstadoModal(true)
    }

    const handlerEditar = (item) => {
        let cliente = item.item
        fijaCliente(cliente)
        cambiarEstadoModal(true)
    }

    const handlerArchivar = (item) => {

    }

    const handlerVerRemisiones = (item) => {
        let cliente = item.item
        Push('lista_remisiones_usuario', {
            usuario: cliente
        })
    }

    const handlerVerDetalles = (item) => {
        let cliente = item.item
        fijaCliente(cliente)
        cambiarEstadoModalDetalles(true)
    }

    const handlerVerMapa = () => {
        Push('mapa_clientes')
    }

    return (
        <>
            <FormUsuario 
                mostrar={mostrarModal}
                handlerMostrar={cambiarEstadoModal}
            />

            <InfoCliente 
                mostrar={mostrarModalDetalles} 
                onClose={() => {
                    cambiarEstadoModalDetalles(false)
                }}
                cliente={cliente}
            />

            <Tabla
                checkbox={false}
                rowSize="medium"
                noData="Aún no hay clientes registrados"
                busqueda={{ placeholder: "Buscar cliente", keys: ["nombre"] }}
                keys={[
                    {
                        icono: 'fa-users',
                        text: "Todos",
                        detalle: 5,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-check',
                        text: "Activos",
                        detalle: 4,
                        filtro: item => item.item.estatus === "liquidado"
                    },
                    {
                        icono: 'fa-user-slash',
                        text: "Archivados",
                        detalle: 1,
                        filtro: item => item.item.estatus === "cobrar"
                    },
                ]}
                headers={[
                    { key: 'nombre',    text: 'Nombre',             type: 'text' },
                    { key: 'correo',    text: 'Correo eléctronico', type: 'text' },
                    { key: 'telefono',  text: 'Teléfono',           type: 'text' },
                    { key: 'municipio', text: 'Municipio',          type: 'text' },
                    { key: 'estatus',   text: 'Estado',             type: 'text' },
                ]}
                data={buildData(data, [
                    { 
                        key: 'nombre',
                        type: 'text',
                        text: (item) => {
                            return (
                                <div style={estiloDivNombre}>
                                    <Miniatura avatar={true} src={obtenerLogo([], 'foto')} />
                                    <span>{item.nombre}</span>
                                </div>
                            )
                        }
                    },
                    { key: 'correo',    type: 'text' },
                    { key: 'telefono',  type: 'text' },
                    { key: 'municipio', type: 'text' },
                    { 
                        key: 'estatus',
                        type: 'text',
                        text: (item) => {
                            let texto, tipo
                            if(item.estatus === ESTATUS_ACTIVO) {
                                texto = 'Activo'
                                tipo = 'Aceptado'
                            }
                            else {
                                texto = 'Cancelado'
                                tipo = 'Error'
                            }
                            return <ChipEstatus texto={texto} tipo={tipo} />
                        }
                    },
                ])}
                botonesHead={[
                    { icono: 'fa-location-dot', tooltip: 'Ver mapa', onClick: handlerVerMapa },
                    { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                ]}
                botonesRow={[
                    { icono: 'fa-file-invoice-dollar', tooltip: 'Ver remisiones', onClick: handlerVerRemisiones },
                    { icono: 'fa-file-pen', tooltip: 'Editar', onClick: handlerEditar },
                    { icono: 'fa-circle-xmark', tooltip: 'Cancelar', onClick: handlerArchivar },
                ]}
                onClickRow={handlerVerDetalles}
            />
        </>
    )
}

export default ListaClientes