import { obtenerLogo } from '../../js/util'
import './SeccionInformacion.css'
import { LazyImage, Button } from 'eureka-design'

const SeccionInformacion = ({ 
    usuario = {},
    botones = [],
    children,
}) => {
    return (
        <div className="seccion-informacion">
            <div className="seccion-informacion__principal">
                <LazyImage 
                    src={obtenerLogo} 
                    avatar={true}
                    width="100px"
                    height = "80px" 
                />
                <div className="seccion-informacion__principal__datos">
                    <h1>{usuario?.nombre}</h1>
                    <p><span>Teléfono: </span>{usuario?.telefono}</p>
                    <p><span>Correo eléctronico: </span>{usuario?.correo}</p>
                </div>
            </div>
            <div className="seccion-informacion__acciones">
                {
                    botones.map((b, i) => {
                        return (
                            <Button 
                                key={i} 
                                texto={b.texto}
                                iconLeft={b.icon}
                                onClick={b.onClick}
                                select={true}
                            />
                        )
                    })
                }
            </div>
            {children}
        </div>
    )
}

export default SeccionInformacion