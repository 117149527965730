import { useState } from 'react'
import nouser from '../../imgs/nouser.jpg'
import iconoEmpresa from '../../imgs/icono.svg'
import { obtenerLogo } from '../../js/util'
import { Theme, Navegador } from 'eureka-design'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { config } from '../../config/config'
import { obtenerRol } from '../../js/rol'
import Perfil from '../perfil/Perfil'
import Inicio from '../inicio/Inicio'
import ListaRemisiones from '../remisiones/lista-remisiones'
import ListaClientes from '../clientes/lista-clientes'
import ListaVendedores from '../vendedores/lista-vendedores'
import ListaRemisionesUsuario from '../usuarios/lista-remisiones'
import MapaClientes from '../mapa/mapa-clientes'
import ListaProductos from '../productos/lista-productos'
import FormProducto from '../productos/form-producto'
import ListaPedidosProveedores from '../pedidos/lista-pedidos-proveedores'
import MovimientosProducto from '../productos/movimientos-producto'

const Index = () => {
    const [usuario] = useState(infoUsuario())

    let opciones = {
        'Inicio': {
            opcion: {
                icono: 'fa-house',
                texto: 'Inicio',
            },
            pantallas: {
                inicio: {
                    props: { usuario },
                    titulo: 'Inicio',
                    componente: Inicio,
                },
            },
        },
        'Remisiones': {
            opcion: {
                icono: 'fa-file-lines',
                texto: 'Remisiones',
            },
            pantallas: {
                lista_remisiones: {
                    props: {},
                    titulo: 'Remisiones',
                    componente: ListaRemisiones,
                },
            },
        },
        'Usuarios': {
            opcion: {
                icono: 'fa-users',
                texto: 'Usuarios',
            },
            pantallas: {
                lista_clientes: {
                    tab: 'Clientes',
                    props: {},
                    titulo: 'Clientes',
                    componente: ListaClientes,
                },
                lista_vendedores: {
                    tab: 'Vendedores',
                    props: {},
                    titulo: 'Vendedores',
                    componente: ListaVendedores,
                },
                lista_remisiones_usuario: {
                    props: {},
                    titulo: 'Remisiones de Usuario',
                    componente: ListaRemisionesUsuario,
                },
                mapa_clientes: {
                    props: {},
                    titulo: 'Mapa de clientes',
                    componente: MapaClientes,
                },
            },
        },
        'Productos': {
            opcion: {
                icono: 'fa-cow',
                texto: 'Productos',
            },
            pantallas: {
                lista_productos: {
                    tab: 'Inventario',
                    props: {},
                    titulo: 'Productos',
                    componente: ListaProductos,
                },
                movimientos: {
                    tab: 'Pedidos proveedores',
                    props: {},
                    titulo: 'Pedidos',
                    componente: ListaPedidosProveedores,
                },
                form_producto: {
                    props: {},
                    titulo: 'Agregar producto',
                    componente: FormProducto,
                },
                lista_movimientos: {
                    props: {},
                    titulo: 'Movimientos',
                    componente: MovimientosProducto,
                }
            },
        },
        'Perfil': {
            pantallas: {
                lista_resetas: {
                    props: {},
                    titulo: 'Mi perfil',
                    componente: Perfil,
                },
            }
        }
    }

    return (
        <Theme
            darkColor={config.colorSecundario}
            lightColor={config.colorPrimario}
            compareDark={config.colorPrimario}
        >
            <Navegador
                themeDefault="light"
                empresa={{
                    nombre: "ServiCarnes",
                    imagen: iconoEmpresa,
                    version: "v1.0.0-061124 demo",
                }}
                usuario={{
                    nombre: usuario?.nombre ?? 'Sin nombre',
                    rol: obtenerRol(usuario),
                    imagen: obtenerLogo(usuario?.eureka_cr_multimedia, 'logo', nouser),
                    pantalla: "Perfil"
                }}
                componentes={opciones}
            ></ Navegador>
        </Theme>
    )
}

export default Index