import './Fila.css'

const Fila = ({ 
    children, 
    agregarBorde = true,
    agregarFondo = false,
}) => {
    return (
        <div
            className={`
                fila-concepto
                ${agregarBorde ? '' : 'fila-concepto--sin-borde'}
                ${agregarFondo ? 'fila-concepto--fondo' : ''}
            `}
        >
            {children}
        </div>
    )
}
export default Fila