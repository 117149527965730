import { Tabla, buildData, ChipEstatus } from 'eureka-design'

const MovimientosProducto = ({ UpProps }) => {
    const data = [
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', tipo: 'liquidado' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', tipo: 'cobrar' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', tipo: 'pendiente' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', tipo: 'liquidado' },
        { id: 12345, cliente: 'Juan Gabriel Martinez Lopez', productos: 4, kilos: 20, costo: 800, fecha: '2024-10-15', tipo: 'pendiente' },
    ]

    return (
        <>
            <Tabla
                checkbox={false}
                rowSize="medium"
                noData="No hay movimientos aún"
                busqueda={{ placeholder: "Buscar...", keys: ["id"] }}
                keys={[
                    {
                        icono: 'fa-bars',
                        text: "Todos",
                        detalle: 200,
                        filtro: () => true
                    },
                    {
                        icono: 'fa-circle-dollar-to-slot',
                        text: "Ventas",
                        detalle: 140,
                        filtro: item => item.item.tipo === "liquidado"
                    },
                    {
                        icono: 'fa-money-check-dollar',
                        text: "Compras",
                        detalle: 50,
                        filtro: item => item.item.tipo === "cobrar"
                    },
                    {
                        icono: 'fa-rotate',
                        text: "Cambios en costo",
                        detalle: 10,
                        filtro: item => item.item.tipo === "pagar"
                    },
                ]}
                headers={[
                    { key: 'fecha',     text: 'Fecha',                 type: 'date' },
                    { key: 'id',        text: 'N° de Pedido',          type: 'text' },
                    { key: 'tipo',      text: 'Tipo',                  type: 'text' },
                    { key: 'productos', text: 'Productos solicitados', type: 'text' },
                    { key: 'kilos',     text: 'Kg total',              type: 'number' },
                    { key: 'costo',     text: 'Costo',                 type: 'precio' },
                    { key: 'cliente',   text: 'Cliente/Proveedor',     type: 'text' },
                ]}
                data={buildData(data, [
                    { key: 'fecha',     type: 'date' },
                    { key: 'id',        type: 'text' },
                    { 
                        key: 'estatus',
                        type: 'text',
                        text: (item) => {
                            let texto = '', tipo = ''
                            if(item.tipo === 'liquidado') {
                                texto = 'Venta'
                                tipo = 'Aceptado'
                            }
                            else if(item.tipo === 'cobrar') {
                                texto = 'Cambio en costo'
                                tipo = 'Pendiente'
                            }
                            else {
                                texto = 'Compra'
                                tipo = 'Error'
                            }
                            return <ChipEstatus texto={texto} tipo={tipo}/>
                        }
                    },
                    { key: 'productos', type: 'text' },
                    { key: 'kilos',     type: 'number' },
                    { 
                        key: 'costo',
                        type: 'precio',
                        text: (item) => `${item.productos} productos`
                    },
                    { key: 'cliente',   type: 'text' },
                ])}
                botonesHead={[

                ]}
                onClickRow={() => {}}
            />
        </>
    )
}

export default MovimientosProducto