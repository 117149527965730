import './Firma.css'
import { useRef } from 'react'
import { Card, ButtonIcon } from 'eureka-design'
import SignatureCanvas from 'react-signature-canvas'

const Firma = ({ 
    titulo = 'Firma de conformidad',
    subTitulo = 'Ingresa tu firma en el siguiente recuadro',
    onSave = () => {}
}) => {
    const sigCanvas = useRef(null)

    const borrar = () => {
        sigCanvas.current.clear()
    }

    const guardar = () => {
        const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        onSave(dataURL)
    }

    return (
        <Card>
            <div className="contenedor-firma">
                <p className="contenedor-firma__titulo">{titulo}</p>
                <p className="contenedor-firma__sub-titulo">{subTitulo}:</p>
                <div className="contenedor-firma__firma">
                    <div className="contenedor-firma__firma__acciones">
                        <span 
                            className="contenedor-firma__firma__acciones__boton" 
                            onClick={borrar}
                        >
                            Borrar firma
                        </span>
                        <ButtonIcon icono="fa-floppy-disk" onClick={guardar} tooltip="Guardar" />
                    </div>
                    <div style={{ margin: 'auto' }}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{
                                width: 500,
                                height: 200,
                                className: 'sigCanvas',
                            }}
                        />
                    </div>
                    <div className="contenedor-firma__firma__linea"></div>
                </div>
            </div>
        </Card>
    )
}

export default Firma