import CardDetalle from "../../components/CardDetalle/CardDetalle"
import ModalDetalles from "../../components/ModalDetalles/ModalDetalles"
import SeccionInformacion from "../../components/SeccionInformacion/SeccionInformacion"
import { numberFormat } from "../../js/util"
import mapa from "../../imgs/mapa-demo.png"

const estiloSubTitulo = {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '16px',
}

const estiloDisplayFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px'
}

const InfoCliente = ({
    mostrar = true,
    onClose = () => {},
    cliente = {},
}) => {
    return (
        <ModalDetalles
            mostrar={mostrar}
            onClose={onClose}
            titulo="Detalle cliente"
        >
            <SeccionInformacion
                usuario={cliente}
                botones={[
                    { texto: 'Editar', icon: 'fa-pen', onClick: () => {} },
                    { texto: 'Eliminar', icon: 'fa-trash-can', onClick: () => {} },
                ]}
            >
                <h2 style={estiloSubTitulo}>Dirección</h2>
                <p style={{ fontSize: '16px' }}>La Piedad N°12, Alameda, El Salto, Jalisco</p>
                <img style={{ width: '488px', height: 'auto' }} src={mapa} alt="mapa" />

                <h2 style={estiloSubTitulo}>100 pedidos realizados</h2>
                <div style={estiloDisplayFlex}>
                    <CardDetalle 
                        titulo="Agosto"
                        texto={numberFormat(10000)}
                        mostrarIcono={false}
                    />
                    <CardDetalle 
                        titulo="Total"
                        texto={numberFormat(10000)}
                        mostrarIcono={false}
                    />
                </div>

                <h2 style={estiloSubTitulo}>20 remisiones</h2>
                <div style={estiloDisplayFlex}>
                    <CardDetalle 
                        titulo="Sin pagar"
                        texto={0}
                        icono="fa-triangle-exclamation"
                        color="#EF1F1F"
                    />
                    <CardDetalle 
                        titulo="Pendientes"
                        texto={2}
                        icono="fa-triangle-exclamation"
                        color="#FFB800"
                    />
                    <CardDetalle 
                        titulo="Pagadas"
                        texto={98}
                        icono="fa-circle-check"
                        color="#26BE19"
                    />
                </div>
            </SeccionInformacion>
        </ModalDetalles>
    )
}

export default InfoCliente