import InformacionVenta from "../../components/InformacionVenta/InformacionVenta"
import ModalDetalles from "../../components/ModalDetalles/ModalDetalles"

const DetalleRemision = ({ 
    remision,
    mostrar = true,
    onClose = () => {}
}) => {
    return (
        <ModalDetalles
            mostrar={mostrar}
            onClose={onClose}
            titulo="Detalle remisión"
        >
            <InformacionVenta />
        </ModalDetalles>
    )
}

export default DetalleRemision