import { useEffect } from 'react'
import { Formulario, ImageInput, Input, Select } from 'eureka-design'
import InputCount from '../../components/InputCount/InputCount'

const FormProducto = ({ producto, titulo, UpTittle, Push }) => {
    useEffect(() => {
        UpTittle(titulo ?? 'Agregar producto')
    })

    return (
        <Formulario
            botones={[
                { texto: 'Ver movimientos', onClick: () => { Push('lista_movimientos') } }
            ]}
        >
            <div style={{ display: 'flex', gap: '24px '}}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'space-between' }}>
                    <div style={{ flexGrow: 1 }}>
                        <ImageInput 
                            width={250} 
                            height={250}
                        />
                    </div>
                    
                    <InputCount 
                        label="Existencia"
                        name="existencia"
                        value={producto?.inventario}
                        min={0}
                        max={99}
                        onChange={(nom, val) => {
                            console.log(nom)
                            console.log(val)
                        }}
                    />
                </div>
                <div style={{ flexGrow: 3, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Input 
                        placeholder="Nombre del producto"
                        value={producto?.nombre}
                    />
                    <Input 
                        placeholder="Código del producto"
                        value={producto?.id}
                    />
                    <Input 
                        placeholder="Costo por Kg"
                        value={producto?.precio}
                    />
                    <Select 
                        placeholder="Categoría"
                        value={producto?.categoria ?? 'Selecciona una categoría...'}
                    />
                    <Select 
                        placeholder="Marca"
                        value={producto?.marca ?? 'Selecciona una marca...'}
                    />
                    <Input 
                        type="textarea"
                        placeholder="Descripción"
                    />
                </div>
            </div>
        </Formulario>
    )
}

export default FormProducto