import Columna, { TAM_SM } from "../Columna/Columna"
import Fila from "../Fila/Fila"

const SeccionTotal = ({
    estructura = [],
    total = 0,
    titulo = 'Total',
}) => {
    return (
        <Fila agregarBorde={false}>
            {
                estructura.map((e, i) => {
                    let valor = ''
                    if(i === 1) valor = `${titulo}:`
                    else if(i === estructura.length - 1) valor = total
                    else valor = ''

                    return (
                        <Columna 
                            key={i}
                            tam={e?.tam ?? TAM_SM}
                            agregarBorde={i === 0} 
                            estilos={{
                                color: 'var(--color-primario)',
                                fontSize: '16px'
                            }}
                            alineacion={i === estructura.length - 1 ? 'der' : 'izq'}
                            valor={valor} 
                        />
                    )
                })
            }
        </Fila>
    )
}

export default SeccionTotal