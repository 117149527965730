import './TablaProductos.css'
import { numberFormat } from '../../js/util'
import Categorias from './Categorias/Categorias'
import CardProducto from './CardProducto/CardProducto'
import { Card, Select, Button, ButtonIcon } from 'eureka-design'

const TablaProductos = ({
    noDataMsj = 'No hay registros aún',
    buquedad = {},
    botonesHeader = [],
    onClickCard = () => {},
    data = [],
    categorias = [],
    fotoError = null,
    tipo = '',
}) => {
    return (
        <div className="contenedor-tabla-productos">
            <Categorias 
                titulo={'Categorías'}
                categorias={[
                    { texto: 'Producto 1', subcategorias: [ { texto: 'Sub-Categoría' }, { texto: 'Sub-Categoría' } ] },
                    { texto: 'Producto 2', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                    { texto: 'Nombre categoría', subcategorias: [] },
                ]}
            />

            <Card>
                <div className="contenedor-tabla-productos__tabla">
                    <div className="contenedor-tabla-productos__tabla__header">
                        <div style={{ flexGrow: 3 }}>
                            <Select 
                                nombre={buquedad?.nombre}
                                iconLeft={buquedad?.icono}
                                options={buquedad?.opciones}
                                value={buquedad?.valor ?? buquedad?.placeholder}
                                changeFunction={buquedad?.onChage}
                                busqueda={{ placeholder: buquedad?.placeholder }}
                            />
                        </div>
                        {
                            botonesHeader.map((b, i) => {
                                if(b?.texto?.length > 0) 
                                    return (
                                        <Button 
                                            key={i} 
                                            iconLeft={b.icono} 
                                            texto={b.texto} 
                                            onClick={b.onClick}
                                        />
                                    )
                                else 
                                    return ( 
                                        <ButtonIcon 
                                            key={i} 
                                            icono={b.icono} 
                                            onClick={b.onClick} 
                                            background="var(--color-primario)"
                                            secundario={true}
                                        />
                                    )
                            })
                        }
                    </div>

                    <div className="contenedor-tabla-productos__tabla__grid">
                        {
                            data.length > 0 ?
                                data.map((d, i) => {
                                    let foto = d?.eureka_st_productos_fotos
                                    let precio = d?.eureka_st_productos_precios

                                    foto = foto?.length > 0 ? foto[0] : null
                                    precio = precio?.length > 0 ? precio[0] : null
                                    return (
                                        <CardProducto 
                                            key={i}
                                            id={d.id}
                                            foto={foto?.foto ?? fotoError}
                                            nombre={d.nombre}
                                            precio={`${numberFormat(d?.costo ?? precio.precio)} ${tipo.length > 0 ? `x ${tipo}` : ''}`}
                                            inventario={precio?.numero}
                                            onClick={() => { onClickCard(d) }}
                                        />
                                    )
                                }) :
                                <p className="contenedor-tabla-productos__tabla__grid__mensaje">{noDataMsj}</p>
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default TablaProductos