import './InformacionVenta.css'
import Membrete from "../Membrete/Membrete"
import logo from '../../imgs/icono.svg'
import { Input, Tabla, buildData } from 'eureka-design'
import CardDetalle from '../CardDetalle/CardDetalle'

const InformacionVenta = ({
    cliente = {},
    venta = {},
}) => {
    const producto = [
        { id: '1234', cantidad: 4, precio: 100, total: 400 },
        { id: '1234', cantidad: 4, precio: 100, total: 400 },
        { id: '1234', cantidad: 4, precio: 100, total: 400 },
    ]

    return (
        <div className="contenedor-informacion-venta">
            <Membrete 
                logo={logo}
                tam="sm"
            />
            <div className="contenedor-informacion-venta__datos">
                <Input 
                    type="textarea"
                    iconLeft="fa-user"
                    placeholder="Datos del cliente"
                    value="Ana María Marcela"
                />
                <div className="contenedor-informacion-venta__datos__lateral">
                    <Input 
                        placeholder="Remisión"
                        value="N° 0467"
                    />
                    <Input 
                        type="date"
                        placeholder="Fecha"
                        value="2024-08-28"
                    />
                </div>
            </div>

            <br />
            <Tabla 
                noData="No hay productos para esta remisión"
                headers={[
                    { key: 'id',       text: 'Producto', type: 'text' },
                    { key: 'cantidad', text: 'Cantidad', type: 'number' },
                    { key: 'precio',   text: 'Precio',   type: 'precio' },
                    { key: 'total',    text: 'Total',    type: 'precio' },
                ]}
                data={buildData(producto, [
                    { key: 'id',       type: 'text' },
                    { key: 'cantidad', type: 'number' },
                    { key: 'precio',   type: 'precio' },
                    { key: 'total',    type: 'precio' },
                ])}
            />

            <br />
            <CardDetalle 
                titulo='Pagaré'
                texto='Debo y pagaré incondicionalmente a la orden de SERVI CARNES DE OCCIDENTE, S.A. DE C.V. en esta ciudad de. GUADALAJARA, JAL. El día 28 de Agosto de 2024 la cantidad de $25,000.00 (Veinticinco mil quinientos pesos) M.N. Valor de la mercancía recibidas a mi entera satisfacción. Este pagaré es mercantil y está regido por la Ley General del Títulos y Operaciones de crédito en su Artículo 173 parte final y artículos correctivos por no ser pagaré domiciliado.'
                mostrarIcono={false}
                modoInformacionGrande={true}
            />
        </div>
    )
}

export default InformacionVenta