import { MESES, obtenerData } from '../../js/util'
import { useEffect, useState } from 'react'
import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useObtenerRemisionesQuery } from '../../services/remision'

const Inicio = ({ UpTittle, ChangeScene, usuario }) => {
    const { data, isLoading } = useObtenerRemisionesQuery()

    const [fecha] = useState(new Date())
    
    useEffect(() => {
        UpTittle(`Hola ${usuario?.nombre ?? 'usuario'}`)
    })

    if(isLoading) return
    else {
        return (
            <>
                <h3 style={{ padding: '12px' }}>
                    Remisiones del 01 al {fecha.getDate()} de {MESES[fecha.getMonth()]} del {fecha.getFullYear()}
                </h3>
                <Tabla 
                    checkbox={false}
                    noData="No hay remisiones aún"
                    headers={[
                        { key: 'id',               text: 'N° de Pedido',          type: 'text' },
                        { key: 'cliente',          text: 'Cliente',               type: 'text' },
                        { key: 'estatus_whatsapp', text: 'Enviado por Whatsapp',  type: 'text' },
                        { key: 'productos',        text: 'Productos Solicitados', type: 'text' },
                        { key: 'total',            text: 'Kg Total',              type: 'number' },
                        { key: 'costo',            text: 'Costo',                 type: 'precio' },
                        { key: 'fecha',            text: 'Fecha de Creación',     type: 'date' },
                        { key: 'cxp_cxc',          text: 'Estado',                type: 'text' },
                    ]}
                    data={buildData(data, [
                        { key: 'id', type: 'text' },
                        { key: 'cliente', type: 'text' },
                        { 
                            key: 'estatus_whatsapp', 
                            type: 'text', 
                            text: (item) => {
                                let guia = obtenerData(item, 'eureka_en_guias')
                                let texto = ''
                                let tipo = ''

                                if(guia.estatus === 'enviado') {
                                    texto = 'Enviada'
                                    tipo = 'Aceptado'
                                }
                                else if(guia.estatus === 'esperando') {
                                    texto = 'Sin enviar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                        { 
                            key: 'productos', 
                            type: 'text',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                return `${conceptos.length} productos`
                            }
                        },
                        { 
                            key: 'kilo', 
                            type: 'number',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                let total = 0

                                conceptos.forEach((c) => {
                                    total += parseFloat(c.cantidad)
                                })
                                return total
                            }
                        },
                        { key: 'total', type: 'precio' },
                        { key: 'fecha', type: 'date' },
                        { 
                            key: 'cxp_cxc', 
                            type: 'text',
                            text: (item) => {
                                let texto = '', tipo = ''
                                if(item.cxp_cxc === 'liquidado') {
                                    texto = 'Pedido pagado'
                                    tipo = 'Aceptado'
                                }
                                else if(item.cxp_cxc === 'cobrar') {
                                    texto = 'Pendiente de pago'
                                    tipo = 'Pendiente'
                                }
                                else {
                                    texto = 'Sin pagar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                    ])}
                    botonesHead={[
                        {
                            icono: 'fa-arrow-right',
                            tooltip: 'Ver más',
                            onClick: () => {
                                ChangeScene('Remisiones', 'lista_remisiones', {})
                            }
                        },
                    ]}
                />
            </>
        )
    }
}

export default Inicio