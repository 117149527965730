import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_PRODUCTO = 'EurekaStProducto'

export const productoAPI = createApi({
    reducerPath: 'productoAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Productos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerProductos: builder.query({
            /**
             * @param {{ estatus: string }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTO)
                body = (data?.estatus) ? body.where('estatus', data.estatus) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_productos_precios')
                              .with('eureka_st_productos_fotos.eureka_cr_multimedia')
                              .get()
                }
            },
        }),
    })
})


export const { 
    useObtenerProductosQuery
} = productoAPI