import BotonIcono from '../BotonIcono/BotonIcono'
import './Columna.css'

export const TAM_SM = 'sm'
export const TAM_MD = 'md'
export const TAM_LG = 'lg'
export const TAM_Xl = 'xl'
export const TIPO_NORMAL = 'normal'
export const TIPO_CABECERA = 'cabecera'

const Columna = ({
    children,
    valor = null, 
    tipo = TIPO_CABECERA, 
    tam = TAM_SM, 
    agregarBorde = true,
    alineacion = 'izq',
    estilos = {},
    icono = '',
    seleccionable = false,
    onClick = () => {},
    incrementable = false,
    onChange = () => {}
}) => {
    return (
        <div 
            style={estilos}
            className={`
                columna-concepto 
                columna-concepto--${tam} 
                ${tipo === TIPO_CABECERA ? 'columna-concepto--cabecera' : ''} 
                ${agregarBorde ? '' : 'tabla-conceptos__cabezera__celda--sin-border'}
                columna-concepto--alineacion-${alineacion}
                ${seleccionable ? 'columna-concepto--boton' : ''}
                ${incrementable ? 'columna-concepto--espaciado' : ''}
            `}
            onClick={
                seleccionable ? 
                    (e) => { 
                        e.stopPropagation()
                        onClick()
                    } : () => {}}
        >
            {
                incrementable ? 
                    <BotonIcono 
                        icono='fa-plus' 
                        onClick={(e) => {
                            e.stopPropagation()
                            onChange(1, 'incrementar')
                        }} 
                    /> : undefined
            }
            {
                icono?.length > 0 ?
                    <i className={`fa-solid ${icono}`}></i> :
                    undefined
            }
            { 
                valor ? <span className={`columna-concepto__${tipo}`}>{valor}</span> : undefined 
            }
            {
                incrementable ? 
                    <BotonIcono 
                        icono='fa-minus' 
                        onClick={(e) => {
                            e.stopPropagation()
                            onChange(-1, 'incrementar')
                        }} 
                    /> : undefined
            }
            {children}
        </div>
    )
}

export default Columna