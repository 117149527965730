import { configureStore } from '@reduxjs/toolkit'
import sesionReducer from '../feactures/SesionSlice'
import { usuarioAPI } from '../services/usuario'
import { rolAPI } from '../services/rol'
import { remisionAPI } from '../services/remision'
import { productoAPI } from '../services/producto'
import { clienteAPI } from '../services/cliente'
import { vendedorAPI } from '../services/vendedor'

export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        [usuarioAPI.reducerPath]: usuarioAPI.reducer,
        [rolAPI.reducerPath]: rolAPI.reducer,
        [remisionAPI.reducerPath]: remisionAPI.reducer,
        [productoAPI.reducerPath]: productoAPI.reducer,
        [clienteAPI.reducerPath]: clienteAPI.reducer,
        [vendedorAPI.reducerPath]: vendedorAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({serializableCheck: false}),
        usuarioAPI.middleware,
        rolAPI.middleware,
        remisionAPI.middleware,
        productoAPI.middleware,
        clienteAPI.middleware,
        vendedorAPI.middleware,
    ],
});