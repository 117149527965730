import { Card } from 'eureka-design'
import Mapa from '../../components/Mapa/Mapa'
import mapa from '../../imgs/mapa-clientes-demo.png'

const MapaClientes = ({ clientes = [] }) => {
    return (
        <Card>
            <Mapa 
                src={mapa}
                titulo="Andrea López Martínez"
                descripcion="Calle Caracol N°24 La Corregidora, La Palma, Guadalajara, 12345"
                detalles={[
                    { texto: '112 clientes en total' },
                    { texto: '9 clientes en esta zona' },
                ]}
            />
        </Card>
    )
}

export default MapaClientes