import { useEffect, useState } from 'react'
import Columna, { TAM_SM } from './Columna/Columna'
import './TablaConceptos.css'
import { adaptarDatos } from './js/adaptar_datos'
import SeccionAgregar from './SeccionAgregar/SeccionAgregar'
import SeccionTotal from './SeccionTotal/SeccionTotal'
import { numberFormat } from './js/adaptar_columna'
import FilaAgregar from './FilaAgregar/FilaAgregar'

export const MODO_FORM = 'form'
export const MODO_VISUAL = 'visual'

const TablaConceptos = ({    
    productos = [],
    encabezados = [],
    conceptos = [],
    estructura = [],
    botonesFila = [],
    onChange = () => {},
    modo = MODO_FORM,
    total = 0,
    etiquetaTotal = 'TOTAL'
}) => {
    const [encabezadosTabla] = useState(encabezados)
    const [estructuraTabla] = useState(estructura)

    const [verFilaAgregar, mostrarFilaAgregar] = useState(false)
    const [conceptosAgregados, fijaConceptos] = useState(conceptos)

    useEffect(() => {
        fijaConceptos(conceptos)
    }, [fijaConceptos, conceptos])

    const handlerAgregar = () => {
        mostrarFilaAgregar(!verFilaAgregar)
    }

    const handlerCambiarValorCol = (indiceConcepto = null, concepto = null, estructura = null, tipo = '', valor = '') => {
        if(indiceConcepto >= 0 && concepto && estructura) {
            if(tipo === 'editar') {

            }
            else if(tipo === 'incrementar') {
                valor = parseInt(concepto[estructura?.col]) + parseInt(valor)
                concepto = {
                    ...concepto,
                    [estructura?.col]: valor > 0 ? valor : 1
                }
            }
            // else tipo no reconocido
            onChange('editar', concepto, indiceConcepto)
        }
        // else no hacer ningúna modificación
    }

    return (
        <div className="tabla-conceptos">
            <div className="tabla-conceptos__cabezera">
                {
                    encabezadosTabla?.map((e, i) => {
                        return (
                            <Columna 
                                key={i}
                                valor={e?.texto ?? ''}
                                tam={e?.tam ?? TAM_SM}
                                agregarBorde={i < (encabezados.length-1)}
                            />
                        )
                    })
                }
            </div>

            <div className="tabla-conceptos__cuerpo">
                {adaptarDatos(conceptosAgregados, estructuraTabla, modo, handlerCambiarValorCol)}
                {
                    verFilaAgregar ? 
                        <FilaAgregar 
                            estructura={estructuraTabla}
                            totalConceptos={conceptosAgregados.length}
                            prodcutos={productos}
                            onChange={(val, nom) => {
                                mostrarFilaAgregar(false)
                                onChange('agregar', val.item)
                            }}
                        /> : undefined
                }
            </div>

            {
                modo === MODO_FORM ?
                    <div className="tabla-conceptos__cuerpo">
                        <SeccionAgregar 
                            estructura={estructuraTabla}
                            icono="fa-plus"
                            titulo="Agregar producto"
                            indiceBoton={0}
                            onClick={handlerAgregar}
                        />
                    </div> : undefined
            }

            <div className="tabla-conceptos__total">
                <SeccionTotal 
                    estructura={estructuraTabla}
                    titulo={etiquetaTotal}
                    total={numberFormat(total)}
                />
            </div>
        </div>
    )
}

export default TablaConceptos